import React from 'react'
// import { Map, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
// import L from 'leaflet'
// import { graphql } from 'gatsby'
// import Routes from '../components/Routes'
import Map from '../components/ImperativeMap'
import RouteInfo from '../components/RouteInfo'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

App.propTypes = {
  data: PropTypes.object
}

function App ({data}) {

  // State storage for the map, which is managed by Leaflet
  const [selectedRoute, setSelectedRoute] = React.useState(null)

  const [routes, setRoutes] = React.useState(null)

  function onRouteSelected(feature) {

    setSelectedRoute(feature)
  }

  // Unpack the routes every time the data changes (which should be once on load)
  React.useEffect(() => {

    const routes = []

    for (const dataNode of data['allGeoJson'].nodes) {

      // Route name - if it's defined in text then use that, otherwise, use the directory name
      let routeName = dataNode.parent.relativeDirectory
      let routeDetails = null

      if (dataNode.text !== null) {

        if (dataNode.text.frontmatter.name !== null) {
          routeName = dataNode.text.frontmatter.name
        }

        routeDetails = dataNode.text.html
      }


      // Unpack values
      const route = dataNode.feature

      // Patch in the ID/bounds
      route.properties.name = routeName
      route.properties.details = routeDetails
      route.properties.bounds = dataNode.bounds
      route.properties.length = dataNode.length

      routes.push(route)
    }

    setRoutes(routes)

  }, [data])

  return (

    // Build the map component, setting a callback for when a route is clicked
    <React.Fragment>
      <Map routes={routes} onRouteSelected={onRouteSelected} />
      <RouteInfo route={selectedRoute} />
    </React.Fragment>
  )
}

export const query = graphql`
    query MapDataQuery {
        allGeoJson {
            nodes {
                bounds
                length
                text {
                    html
                    frontmatter {
                        name
                    }
                }
                feature {
                    geometry {
                        coordinates
                        type
                    }
                    properties {
                        name
                    }
                    type
                }
                parent {
                    ... on File {
                        relativeDirectory
                    }
                }
            }
        }
    }
`

export default App