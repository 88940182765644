import React from 'react'
import PropTypes from 'prop-types'

RouteInfo.propTypes = {
  route: PropTypes.object
}

function RouteInfo({ route }) {

  const [expanded, setExpanded] = React.useState(false)

  function handleExpand() {

    setExpanded(true)
  }

  function handleContract() {

    setExpanded(false)
  }

  // If no route is selected, show default text
  if (route === null) {
    return(
      <div id="route-info-container">
          <div id="route-info">
            <div id="name">
              <h1><strong>Click a route for details...</strong></h1>
            </div>
          </div>
      </div>
    )
  }

  // Length in kms
  const lengthString = (route.properties.length / 1000).toFixed(0) + ' km'


  // Otherwise, show either the expanded or contracted version
  if (expanded) {

    return(
      <div id="route-info-container">
        <div id="route-info" className="expanded" >
          <div id="name">
            <h1><strong>{route.properties.name}</strong> - {lengthString}</h1>
            <a href="#" className="button" onClick={handleContract}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12
                5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5 15.538l-3.592-3.548
                3.546-3.587-1.416-1.403-3.545 3.589-3.588-3.543-1.405 1.405 3.593 3.552-3.547 3.592 1.405 1.405
                3.555-3.596 3.591 3.55 1.403-1.416z"/>
              </svg>
            </a>
          </div>
          <div id="details" dangerouslySetInnerHTML={{ __html: route.properties.details }} />
        </div>
      </div>
    )
  }
  else {

    return(
      <div id="route-info-container">
        <div id="route-info">
          <div id="name">
            <h1><strong>{route.properties.name}</strong> - {lengthString}</h1>
            <a href="#" className="button" onClick={handleExpand}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M13.25 7c0 .69-.56 1.25-1.25 1.25s-1.25-.56-1.25-1.25.56-1.25 1.25-1.25 1.25.56 1.25
                         1.25zm10.75 5c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-2
                         0c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10 10-4.486 10-10zm-13-2v2h2v6h2v-8h-4z"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default RouteInfo