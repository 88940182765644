import React from 'react'
import L from 'leaflet'
import PropTypes from 'prop-types'

// Need to be included to patch leaflet
import 'leaflet-geometryutil'
import 'leaflet-almostover'

// Re-create the Leaflet "default" marker icon - for some reason the "out of the box" setup doesn't work with Webpack -
// the icons get lost. This replacement makes sure the paths are correct and everything works without error. All off the
// values here are identical to the defaults in Leaflet
// const defaultIcon = new L.Icon({
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   tooltipAnchor: [16, -28],
//   shadowSize: [41, 41]
// })

Map.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  onRouteSelected: PropTypes.func
}

function Map({ routes, onRouteSelected }) {

  // State storage for the map, which is managed by Leaflet
  const [map, setMap] = React.useState(null)

  // State storage for rout layers
  const [geoJsonLayers, setGeoJsonLayers] = React.useState(null)

  // State storage for selected route
  const [selectedRoute, setSelectedRoute] = React.useState(null)

  // If the selected route has changed (and isn't null), then zoom to it and bring it to the front
  React.useEffect(() => {

    // Layer group may not be initialised
    if (geoJsonLayers !== null) {

      // Loop through the "layers" (which are in fact GeoJSON objects)
      geoJsonLayers.eachLayer(geoJsonLayer => {

        if (selectedRoute !== null && geoJsonLayer.hasLayer(selectedRoute)) {

          geoJsonLayer.bringToFront()
          geoJsonLayer.setStyle({'weight': 5})

          map.flyToBounds(geoJsonLayer.getBounds(), {padding: [10, 10]})
        }
        else {

          geoJsonLayer.setStyle({'weight': 3})
        }
      })
    }

  }, [map, geoJsonLayers, selectedRoute])

  // Run once after first render - initialise map in async fashion. Note that we *do* pass dependencies to this effect
  // but *in theory* they are all things that never change, so the map initialisation should only be run once
  React.useEffect(() => {

    // When a route is clicked we want to update the map display
    function onRouteClicked(layer) {

      // Set the local state
      // ToDo: is this even needed? Could this be stored at the higher level?
      setSelectedRoute(layer)

      // Call up to higher level component
      onRouteSelected(layer.feature)
    }

    function selectColor(number) {
      
     const palette = ['#7a9d52', '#5832cd', '#4ea829', '#a34ce4',
       '#839d28', '#d43dd3', '#43a855', '#6c31aa',
       '#a9952b', '#4f57d8', '#cd8f2b', // '#6a78e2',
       '#e53d1d', '#5d8edc', '#e17323', '#4a4a93',
       '#387527', '#da43ae', '#315b22', '#c06eda',
       '#5e7024', '#893492', '#49a789', '#e13149',
       '#307c55', '#e64289', '#3a3f09', '#a27fc7',
       '#8b721c', '#4a6ca5', '#e86444', '#499fce',
       '#b33925', '#74376d', '#95904d', '#9d2466',
       '#625910', '#ac5693', '#4c501a', '#d979a9',
       '#716c37', '#c83d5c', '#b28b52', '#a3566d',
       '#c27438', '#87313a', '#d17e6a', '#7c4d20',
       '#df6b72', '#903c1e']
  
      // const hue = number * 137.508 // use golden angle approximation
      // return `hsl(${hue},100%,50%)`
      return palette[number]
    }

    // create map, but only if it doesn't exist - if it does (e.g. if this is re-rendered) then we ignore that
    // request, because Leaflet will throw an error
    if (map === null && routes !== null) {

      const builtMap = L.map('leaflet-container', {
        attributionControl: false,
        center: [54.6, -3.7],
        zoom: 7,
        layers: [
          L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
          }),
        ],
        almostOnMouseMove: false
      })

      L.control.attribution({ position: 'topright' }).addTo(builtMap)

      // Loop through the data returned from our query and parse-out the data we need to manage routes on the page
      // const features = unpackRoutes()
      const layerGroup = L.layerGroup()

      for (const [index, feature] of routes.entries()) {

        const geoJson = L.geoJSON(feature, {
          color: selectColor(index)
        })

        layerGroup.addLayer(geoJson)
      }

      // Add the layers to the map
      layerGroup.addTo(builtMap)

      // Add the layer group to "almostover"
      for (const layer of layerGroup.getLayers()) {

        builtMap.almostOver.addLayer(layer)
      }

      builtMap.on('almost:click', function ({layer}) {
        onRouteClicked(layer)
      })

      // Store the layer group for later use
      setGeoJsonLayers(layerGroup)

      // Store the map, so the rest of this component can access it
      setMap(builtMap)
    }

  }, [routes, map, onRouteSelected])

  // Actual render of the component is just this: a single div
  return <div id="leaflet-container"/>
}

export default Map
